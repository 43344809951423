import Box from '@amzn/meridian/box';
import DateRangePicker from '@amzn/meridian/date-range-picker';

import Heading from '@amzn/meridian/heading';
// import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { getClaimDocumentUrl } from '../clients/HrpsClaimsApi';
import { BorderedColumn, GreyBox, WaitFor } from '../components/blocks';
import { SearchField, Text, useTranslation } from '../components/blocks/localization';
import { ClaimStatusIcon } from '../components/composites';
import { ClaimReferenceCell } from '../components/composites/ClaimReferenceCell';
import { PaginatedTable } from '../components/constructed';
import { ALL_RESULTS_NUM, CLAIMS_PER_PAGE, MAX_CLAIM_RESULTS } from '../constants';
import {
    claimChangePage,
    claimLoadMoreData,
    claimUpdateDateRange,
    claimUpdateQuery,
    claimUpdateSortChange,
    getClaimList,
    getClaimListData,
} from '../redux/actions/claimListActionCreators';
import { getResellerDetails } from '../redux/actions/resellerActionCreators';
import { ClaimListClaim, ClaimTableSortDirection, CreditMemoStatus, RootState, TableData } from '../redux/types';
import { IAsynchronouslyLoadedValue } from '../redux/types/asynchronouslyLoadedValue';
import { IAuthorizedReseller } from '../redux/types/reseller';

type SortData = {
    sortColumn: string;
    sortDirection: string;
};

const ClaimListPage: FC<ClaimListPageProps> = ({
    reseller,
    resellerDetails,
    claimListState,
    getClaimList,
    getResellerDetails,
    claimChangePage,
    claimLoadMoreData,
    claimUpdateDateRange,
    claimUpdateQuery,
    claimUpdateSortChange,
}: ClaimListPageProps) => {
    const [currentReseller, setCurrentReseller] = useState<IAsynchronouslyLoadedValue<IAuthorizedReseller>>();
    const [query, setQuery] = useState<string>('');
    const { t } = useTranslation('claimsListPage');

    useEffect(
        () => {
            if (
                claimListState.claimListData.status === 'Uninitialized' ||
                currentReseller?.value?.resellerId !== reseller.value?.resellerId
            ) {
                if (reseller.value) {
                    setCurrentReseller(reseller);
                    getClaimList(
                        reseller.value?.resellerId,
                        claimListState.currentPage,
                        MAX_CLAIM_RESULTS,
                        claimListState.sortColumn,
                        claimListState.sortDirection,
                        claimListState.dateRange,
                        claimListState.query
                    );
                }
            }
            if (resellerDetails.status === 'Uninitialized' && reseller.value) {
                getResellerDetails(reseller.value.resellerId);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [claimListState, reseller, resellerDetails]
    );

    const getAllData = async (): Promise<TableData<ClaimListClaim, 'resellerClaimNumber' | 'createdByUser'>> => {
        if (reseller.value) {
            const data = await getClaimListData(
                reseller.value?.resellerId,
                claimListState.currentPage,
                ALL_RESULTS_NUM,
                claimListState.sortColumn,
                claimListState.sortDirection,
                claimListState.dateRange,
                claimListState.query
            );
            return getTableData(data.claimsList);
        }
        const returnOrderData = [] as ClaimListClaim[];
        return getTableData(returnOrderData);
    };

    const submitQuery = useCallback(
        (queryValue: string) => {
            claimUpdateQuery(queryValue);
        },
        [claimUpdateQuery]
    );

    const viewClaimDocument = async (uploadId: string) => {
        if (reseller.value) {
            const result = await getClaimDocumentUrl({
                resellerId: reseller.value?.resellerId,
                uploadId: uploadId,
            });
            window.open(result.documentUrl, '_blank', 'noreferrer');
        }
    };

    const onSort = useCallback(
        (data: SortData) => {
            const { sortColumn, sortDirection } = data;
            claimUpdateSortChange(
                sortColumn,
                ClaimTableSortDirection[sortDirection as keyof typeof ClaimTableSortDirection]
            );
        },
        [claimUpdateSortChange]
    );

    function setDateRange(dateRange: string[]) {
        claimUpdateDateRange(dateRange);
    }

    const onPageChange = (value: string) => {
        const intValue = parseInt(value);
        if (intValue) {
            const calcNewStartRecord = CLAIMS_PER_PAGE * (intValue - 1);
            if (
                calcNewStartRecord < claimListState.startAt + MAX_CLAIM_RESULTS &&
                calcNewStartRecord >= claimListState.startAt
            ) {
                claimChangePage(intValue, calcNewStartRecord);
            } else {
                claimLoadMoreData(intValue, calcNewStartRecord);
            }
        }
    };

    const calculateNumberOfPages = (numOfclaim: number | undefined) => {
        if (numOfclaim) {
            return Math.ceil(numOfclaim / CLAIMS_PER_PAGE);
        }
        return 0;
    };

    const formatDate = (dateNumber: number) => {
        const date = new Date(dateNumber * 1000);
        return date.toISOString().split('T')[0];
    };

    const getCreditMemoLinkOrStatus = (claim: ClaimListClaim) => {
        if (claim.creditMemoLink) {
            return (
                <ClaimReferenceCell
                    translatedText={t('creditMemo', { context: CreditMemoStatus.COMPLETED })}
                    fileId={claim.creditMemoLink}
                    callback={viewClaimDocument}
                />
            );
        } else {
            return claim.creditMemoStatus ? t('creditMemo', { context: claim.creditMemoStatus }) : '';
        }
    };

    const formatData = (claimList: ClaimListClaim[]) => {
        const startPos = (claimListState.currentPage - 1) * CLAIMS_PER_PAGE - claimListState.startAt;
        const endPos = claimListState.currentPage * CLAIMS_PER_PAGE - claimListState.startAt;

        const pageClaimList: ClaimListClaim[] = claimList.slice(startPos, endPos);

        return getTableData(pageClaimList);
    };

    const getTableData = (claimList: ClaimListClaim[]) => {
        const tableData = new TableData(claimList, [
            {
                title: t('claimNumber-columnTitle'),
                uniqueId: 'resellerClaimNumber',
                format: (claim: ClaimListClaim) => (
                    <ClaimReferenceCell
                        translatedText={t.cognate(claim.resellerClaimNumber)}
                        fileId={claim.fileId}
                        callback={viewClaimDocument}
                    />
                ),
            },
            {
                title: t('createdByUser-columnTitle'),
                uniqueId: 'createdByUser',
                format: 'createdByUser',
            },
            {
                title: t('status-columnTitle'),
                uniqueId: 'status',
                format: (claim: ClaimListClaim) => (
                    <ClaimStatusIcon
                        status={claim.status}
                        errors={claim.errorList}
                        key={`${claim.resellerClaimNumber} - ClaimStatusIcon`}
                    />
                ),
            },
            {
                title: t('creationDate-columnTitle'),
                uniqueId: 'creationDate',
                format: (claim: ClaimListClaim) => (
                    <Text key={`${claim.resellerClaimNumber} - creationDateText`}>
                        {t('creationDate-columnValue', { dateTime: formatDate(claim.submissionDate) })}
                    </Text>
                ),
            },
            {
                title: t('creditMemo-columnTitle'),
                uniqueId: 'creditMemoLink',
                format: (claim: ClaimListClaim) => getCreditMemoLinkOrStatus(claim),
            },
        ]);
        return tableData;
    };

    return (
        <BorderedColumn alignmentHorizontal={'center'} spacing={'none'}>
            <GreyBox type={'fill'} spacingInset={'small'} width={'100%'}>
                <Heading level={5} alignment={'left'} data-testid={'claimListPage.header'}>
                    {t('claimList-pageHeading', { count: claimListState.totalRecords })}
                </Heading>
            </GreyBox>
            <WaitFor lazyValue={claimListState.claimListData}>
                {(claimListData) => (
                    <PaginatedTable
                        data={formatData(claimListData)}
                        dataTestId={'claimListTable'}
                        currentPage={claimListState.currentPage}
                        numberOfPages={calculateNumberOfPages(claimListState.totalRecords)}
                        onPageChange={onPageChange}
                        onSort={onSort}
                        sortColumn={claimListState.sortColumn}
                        sortDirection={claimListState.sortDirection}
                        key={`PaginatedTable`}
                        getAllData={getAllData}
                        dataName={t('claimList-pageHeading', { count: claimListState.totalRecords })}
                        rowKey={(rowArray: string[], rowObject: ClaimListClaim) => {
                            return rowObject.resellerClaimNumber;
                        }}
                    >
                        <Box
                            width={'80%'}
                            spacingInset={'none'}
                            data-testid={'claimListPage.filterCriteriaBox'}
                            key={'claimListPage.filterCriteriaBox'}
                        >
                            <Row spacing={'xlarge'} key={'claimListPage.filterCriteriaBox - Row'}>
                                <SearchField
                                    value={query}
                                    onChange={setQuery}
                                    label={t('findClaim-searchPlaceholder')}
                                    onSubmit={submitQuery}
                                    searchButton={true}
                                    data-testid={'claimListPage.searchField'}
                                    key={'claimListPage.filterCriteriaBox - SearchField'}
                                />
                                <Row key={'claimListPage.filterCriteriaBox - Row'} className={'dateRangeContainer'}>
                                    <DateRangePicker
                                        value={claimListState.dateRange}
                                        onChange={setDateRange}
                                        monthsInView={2}
                                        clearButton={true}
                                        size={'medium'}
                                        allowSameStartAndEnd={true}
                                        startLabel={t('dateRangePicker-startPlaceholder')}
                                        endLabel={t('dateRangePicker-endPlaceHolder')}
                                        data-testid={'claimListPage.dateRangePicker'}
                                        key={'claimListPage.filterCriteriaBox - DateRangePicker'}
                                    />
                                </Row>
                            </Row>
                        </Box>
                    </PaginatedTable>
                )}
            </WaitFor>
        </BorderedColumn>
    );
};

const mapStateToProps = ({ resellerReducer, resellerClaimListReducer }: RootState) => {
    return {
        reseller: resellerReducer.selectedReseller,
        resellerDetails: resellerReducer.resellerDetails,
        claimListState: resellerClaimListReducer,
    };
};

const mapDispatchToProps = {
    getClaimList,
    claimChangePage,
    claimLoadMoreData,
    claimUpdateDateRange,
    claimUpdateQuery,
    claimUpdateSortChange,
    getResellerDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ClaimListPageProps = ConnectedProps<typeof connector>;
export default connector(ClaimListPage);
